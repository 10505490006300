<template>
	<div>
		<a-spin :spinning="loading">
			<a-row style="margin-bottom:20px;">
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon>
							<Icon icon="SearchOutlined" />
						</template>
						查询
					</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_transferManage_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon>
								<Icon icon="UploadOutlined" />
							</template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_transferManage_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
						<template #icon>
							<Icon icon="LogoutOutlined" />
						</template>
						导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch()">
						<template #icon>
							<Icon icon="ReloadOutlined" />
						</template>
						刷新
					</a-button>
				</a-col>
			</a-row>

			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formSearch" layout="inline">
					<a-form-item class="ui-form__item" label="申请人姓名">
						<!-- ？？？ -->
						<a-input v-model:value="formSearch.createUserId" placeholder="请输入申请人姓名"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="申请人电话">
						<a-input v-model:value="formSearch.phone" placeholder="请输入申请人电话"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="主管单位">
						<a-select placeholder="请选择主管单位" showSearch optionFilterProp="label" allow-clear style="width: 190px;" v-model:value="formSearch.mangerUnitId">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
								:value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" label="项目名称">
						<a-input v-model:value="formSearch.projectName" placeholder="请输入项目名称"></a-input>
					</a-form-item>
					<a-form-item label="实施单位" class="ui-form__item">
						<a-select placeholder="请选择实施单位" showSearch optionFilterProp="label" allow-clear style="width: 190px;" v-model:value="formSearch.executeUnitId">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
								:value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" label="施工单位">
						<a-input v-model:value="formSearch.constructionCompany" placeholder="请输入施工单位名称"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="申请时间">
						<a-range-picker :value="formSearch.clearTime" @change="onChangeFun" show-time />
					</a-form-item>
					<a-form-item class="ui-form__item" label="状态">
						<a-select allow-clear style="width: 190px;" v-model:value="formSearch.status" placeholder="请选择实状态">
							<a-select-option :value="0">未审核</a-select-option>
							<a-select-option :value="1">已通过</a-select-option>
							<a-select-option :value="2">未通过</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</a-form-item>
				</a-form>
			</div>
			<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun"
				rowSelectionType="checkbox" :searchData="searchData" @rowSelected="rowSelected" :scroll="{x:2000}"
				size="small" bordered draggable>
				<!-- <template #headerCell="{ column }">
					<template v-if="column.key === 'index'">
						<div @click="onShowSelectModal">
							<Icon icon="SettingFilled"></Icon>
						</div>
					</template>
				</template> -->
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'index'">
						{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
					</template>
					<template v-if="column.key === 'action'">
						<a-button type="link" size="small" :disabled="(record.status===1 || record.status === 2)?true:false" @click="onExamine(record,1)">同意</a-button>
						<span></span>
						<a-button type="link" size="small" :disabled="(record.status===1 || record.status === 2)?true:false" @click="onExamine(record,2)">拒绝</a-button>
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-if="column.key === 'bootTime'">
						{{ transDateTime(record.bootTime) }}
					</template>
					<template v-if="column.key === 'isImportant'">
						{{ record.isImportant === 1?'是':'否' }}
					</template>
					<template v-if="column.key === 'stage'">
						<div v-if="record.stage === '0'">基本信息</div>
						<div v-if="record.stage === '1'">建设用地储备</div>
						<div v-if="record.stage === '2'">项目立项</div>
						<div v-if="record.stage === '3'">项目招标</div>
						<div v-if="record.stage === '4'">项目施工</div>
						<div v-if="record.stage === '5'">竣工验收</div>
					</template>
					<template v-if="column.key === 'status'">
						<div v-if="record.status === 0">待处理</div>
						<div v-if="record.status === 1">已通过</div>
						<div v-if="record.status === 2">未通过</div>
					</template>
					<template v-if="column.key === 'classify'">
						<div v-if="classiEcho(record.classify) === '1'">固定资产</div>
						<div v-if="classiEcho(record.classify) === '2'">公共基础设施</div>
						<div v-if="classiEcho(record.classify) === '3'">水利</div>
						<div v-if="classiEcho(record.classify) === '4'">交通</div>
						<div v-if="classiEcho(record.classify) === '5'">市政</div>
						<div v-if="classiEcho(record.classify) === '6'">其它</div>
						<div v-if="classiEcho(record.classify) === '7'">费用支出</div>
					</template>
					<template v-if="column.key === 'projectName'">
						<!-- <a-button type="link" size="small" @click="onDetail(record)">{{record.name}}</a-button> -->
						<div style="color: #1890ff;" @click="onDetail(record)">{{record.projectName}}</div>
					</template>
				</template>
			</c-Table>
			<!-- <a-table rowKey="id" :columns="columns" :data-source="dataList" :pagination="pagination" :customRow="customRow" @resizeColumn="handleResizeColumn" bordered  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{ x: 1500 }">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'index'">
                        {{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
                    </template>
                    <template v-if="column.key === 'action'">
                        <a-button type="link" size="small" @click="onToRecordBtn">同意</a-button>
						<span></span>
                        <a-button type="link" size="small">拒绝</a-button>
                    </template>
                </template>
            </a-table> -->
			<!-- <p>选中{{ 0 }}条记录，投资额总计{{ 0 }}元，已拨付数总计{{ 0 }}元，待拨付数总计{{ 0 }}元</p> -->
		</a-spin>
	</div>
</template>
<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getApplyList,
		portApplyExamine
	} from '@/service/modules/project.js'
	import exportModal from '@/components/exportModal/exportModal.vue';
	import cTable from '@/components/cTable/index.vue';
	export default {
		components: {
			Icon,
			cTable,
			exportModal
		},
		data() {
			return {
				loading: false,
				isSearch: false,
				formSearch: {},
				searchData: {},
				clearTime: [], // 存储时间 用来清空重置
				getDataFun: getApplyList,
				columns: [{
						title: '序号',
						dataIndex: 'index',
						key: 'index',
						rowDrag: true,
						width: 80,
						sorter: {
							compare: (a, b) => a.index - b.index,
							multiple: 10
						}
					},
					{
						title: '申请人',
						dataIndex: 'username',
						key: 'username',
						width:80,
						sorter: {
							compare: (a, b) => a.username - b.username,
							multiple: 9
						},
						resizable: true
					},
					{
						title: '申请人电话',
						dataIndex: 'phone',
						width:80,
						key: 'phone',
						sorter: {
							compare: (a, b) => a.phone - b.phone,
							multiple: 8
						},
						resizable: true
					},
					{
						title: '申请时间',
						dataIndex: 'createTime',
						width:120,
						key: 'createTime',
						sorter: {
							compare: (a, b) => a.createTime - b.createTime,
							multiple: 7
						},
						resizable: true
					},
					{
						title: '主管单位',
						dataIndex: 'mangerUnit',
						width:100,
						key: 'mangerUnit',
						sorter: {
							compare: (a, b) => a.mangerUnit - b.mangerUnit,
							multiple: 6
						},
						resizable: true
					},
					{
						title: '项目名称',
						dataIndex: 'projectName',
						key: 'projectName',
						width:120,
						sorter: {
							compare: (a, b) => a.projectName - b.projectName,
							multiple: 5
						},
						resizable: true
					},
					{
						title: '实施单位',
						dataIndex: 'executeUnit',
						width:120,
						key: 'executeUnit',
						sorter: {
							compare: (a, b) => a.executeUnit - b.executeUnit,
							multiple: 5
						},
						resizable: true
					},
					{
						title: '施工单位',
						dataIndex: 'constructionCompany',
						width:120,
						key: 'constructionCompany',
						sorter: {
							compare: (a, b) => a.constructionCompany - b.constructionCompany,
							multiple: 5
						},
						resizable: true
					},
					{
						title: '项目分类',
						dataIndex: 'classify',
						width:80,
						key: 'classify',
						sorter: {
							compare: (a, b) => a.classify - b.classify,
							multiple: 4
						},
						resizable: true
					},
					{
						title: '是否重大重点项目',
						dataIndex: 'isImportant',
						key: 'isImportant',
						width:80,
						sorter: {
							compare: (a, b) => a.isImportant - b.isImportant,
							multiple: 3
						},
						resizable: true
					},
					{
						title: '项目阶段',
						dataIndex: 'stage',
						width:120,
						key: 'stage',
						sorter: {
							compare: (a, b) => a.stage - b.stage,
							multiple: 2
						},
						resizable: true
					},
					{
						title: '启动时间',
						dataIndex: 'bootTime',
						width:120,
						key: 'bootTime',
						sorter: {
							compare: (a, b) => a.bootTime - b.bootTime,
							multiple: 2
						},
						resizable: true
					},
					{
						title: '状态',
						dataIndex: 'status',
						width:80,
						key: 'status',
						sorter: {
							compare: (a, b) => a.status - b.status,
							multiple: 1
						},
						resizable: true
					},
					{
						title: '操作',
						align: 'center',
						dataIndex: 'action',
						key: 'action',
						width:150,
						fixed:'right'
					},
				],
				dataList: [{
					key: 1,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '昙工商局',
					buildName: 'XXX公司',
					sort: '固定资产',
					isImprotant: '是	',
					startTime: '2022年12月7日',
					state: '待处理',
					action: '321',
				}, {
					key: 2,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日10：30',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '昙工商局',
					buildName: 'XXX公司',
					sort: '固定资产',
					isImprotant: '是	',
					startTime: '2022年12月7日',
					state: '待处理',
					action: '321',
				}, {
					key: 3,
					name: '张三',
					phone: '13888888888',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '昙工商局',
					buildName: 'XXX公司',
					sort: '固定资产',
					isImprotant: '是	',
					startTime: '2022年12月7日',
					state: '待处理',
					action: '321',
				}, {
					key: 4,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '昙工商局',
					buildName: 'XXX公司',
					sort: '固定资产',
					isImprotant: '是	',
					startTime: '2022年12月7日',
					state: '待处理',
					action: '321',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				type: 'projectLoggerExportStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData:{},
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onDetail(item) {
				this.$router.push({
					name: 'projectPreview',
					query: {
						id: item.id,
						// stage: item.stage,
						// isPreview: true,
					}
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formSearch));
				this.pagination.current = 1;
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formSearch = {}
				this.onSearch();
			},
			rowSelected(item){
				this.selIdsData.ids = item.keys.join(',')
			},
			classiEcho(arr) {
				let tempArr = JSON.parse(arr)
				let len = tempArr.length
				return tempArr[len - 1]
			},
			async onExamine(item,status) { // 参数错误
				try {
					let ret = await portApplyExamine({
						id: item.id,
						status
					})
					if (ret.code === 200) {
						this.$message.success('已审核成功')
						// this.getData();
						this.onSearch()
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			onExport() {

			},
			getData() {

			},
			onSort() {

			},
			onFold() {

			},
			handleResizeColumn(w, col) {
				col.width = w;
			},
			customRow(record, index) {
				return {
					draggable: true,
					style: {
						cursor: 'pointer'
					},
					onDragstart: (event) => {
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到源目标数据
						this.sourceIndex = index;
					},
					onDragover: (event) => {
						// 兼容 IE
						var ev = event || window.event
						// 阻止默认行为
						ev.preventDefault()
					},
					onDrop: (event) => {
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到目标数据
						// this.targetObj = record;
						let itemData = this.list.splice(this.sourceIndex, 1);
						this.list.splice(index, 0, itemData[0]);
					}
				}
			},
			onSelectChange(selectedRowKeys) {
			},
			onToRecordBtn() {
				this.$router.push({
					name: 'projectRecord',
					query: {

					}
				})
			},
			onChangeFun(date, dateString) { // 时间变化的回调
				this.clearTime = date
				let starDate = new Date(dateString[0])
				let endDate = new Date(dateString[1])
				// console.log(starDate.valueOf(), endDate.valueOf());
				this.formSearch.startTime = starDate.valueOf()/1000
				this.formSearch.endTime = endDate.valueOf()/1000
			}
			// selectedRowKeys(){

			// }
		}
	}
</script>
<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>
