import {
	send
} from "../index";
export function postProjectBasic(data, opts = {}) { // 项目基本信息录入
	return send({
		url: "/admin/projectBasic/save.do",
		method: "POST",
		data,
		...opts
	})
}
export function updateProjectBasic(data, opts = {}) { // 项目基本信息修改
	return send({
		url: "/admin/projectBasic/fiscalUpdate.do",
		method: "POST",
		data,
		...opts
	})
}
export function postSaveStage(data, opts = {}) { // 信息保存 录入
	return send({
		url: "/admin/projectBasic/saveStage.do",
		method: "POST",
		data,
		...opts
	})
}
export function projectDelete(data, opts = {}) { // 信息保存 录入
	return send({
		url: "/admin/projectBasic/deleteStage.do",
		method: "POST",
		data,
		...opts
	})
}
export function postFiscalUpdateStage(data, opts = {}) { // 阶段信息修改
	return send({
		url: "/admin/projectBasic/fiscalUpdateStage.do",
		method: "POST",
		data,
		...opts
	})
}
export function postUpdateStage(data, opts = {}) { // 实施 阶段信息修改
	return send({
		url: "/admin/projectBasic/updateStage.do",
		method: "POST",
		data,
		...opts
	})
}
export function getMangerSearchList(data, opts = {}) {
	return send({
		url: "/admin/project/mangerSearchList.do",
		method: "POST",
		data,
		...opts
	})
}
export function getExecuteSearchList(data, opts = {}) {
	return send({
		url: "/admin/project/executeSearchList.do",
		method: "POST",
		data,
		...opts
	})
}
export function getFiscalSearchList(data, opts = {}) {
	return send({
		url: "/admin/project/fiscalSearchList.do",
		method: "POST",
		data,
		...opts
	})
}
export function getProjectDetail(data, opts = {}) {
	return send({
		url: "/admin/project/detail.do",
		method: "POST",
		data,
		...opts
	})
}
export function postUpdateRequest(data, opts = {}) { // 实施 修改申请
	return send({
		url: "/admin/updateRequest/save.do",
		method: "POST",
		data,
		...opts
	})
}
export function getMyUnit(data, opts = {}) { // 获取当前单位
	return send({
		url: "/admin/unit/getMyUnit.do",
		method: "POST",
		data,
		...opts
	})
}
export function getOperateLog(data, opts = {}) { // 获取操作日志
	return send({
		url: "/admin/project/operateLog.do",
		method: "POST",
		data,
		...opts
	})
}
export function getApplyList(data, opts = {}) { // 获取操作日志
	return send({
		url: "/admin/updateRequest/searchList.do",
		method: "POST",
		data,
		...opts
	})
}
export function portApplyExamine(data, opts = {}) { // 财政 修改审核
	return send({
		url: "/admin/updateRequest/confirm.do",
		method: "POST",
		data,
		...opts
	})
}
export function getProjectFile(data, opts = {}) { // 文件包下载
	return send({
		url: "/admin/projectBasic/projectFile.do",
		method: "POST",
		data,
		...opts
	})
}
export function getSelectedField(data, opts = {}) { // 获取展示字段
	return send({
		url: "/admin/projectBasic/getSelectedField.do",
		method: "POST",
		data,
		...opts
	})
}
export function setSelectedField(data, opts = {}) { // 设置展示字段
	return send({
		url: "/admin/projectBasic/setSelectedField.do",
		method: "POST",
		data,
		...opts
	})
}

export function downloadProjectFile(data, opts = {}){
	return send({
		url: "/admin/projectBasic/saveDownloadTask.do",
		method: "POST",
		data,
		...opts
	})
}

export function getProjectAnalyse(data, opts = {}){
	return send({
		url: "/admin/projectBasic/analyse.do",
		method: "POST",
		data,
		...opts
	})
}

export function getProjectUnitAnalyse(data, opts = {}){
	return send({
		url: "/admin/projectBasic/unitAnalyse.do",
		method: "POST",
		data,
		...opts
	})
}